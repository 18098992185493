import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Fade from "react-reveal/Fade"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Evolved = () => {
  return (
    <>
      <Layout>
        <Seo title="Evolved from a futuristic accessory to a ubiquitous health & wellness wearable" />
        <Fade top>
          <div className="py-10 lg:py-12 bg-dusk"></div>
        </Fade>
        <div className="py-24">
          <div className="xl:pr-96">
            <div className="container mx-auto text-dusk">
              <div>
                <div className="text-5xl font-firs-medium">
                  Evolved from a futuristic accessory to a ubiquitous health &
                  wellness wearable:
                  {/* <div> Smart Textiles</div> */}
                </div>
                <div className="text-2xl mt-4">
                  Engineering human factors in smartwatches and wristables
                  {/* <div>and smart textiles</div> */}
                </div>
                <div className="mt-4">16th October 2021</div>
              </div>
              <div className="my-4 pt-10">
                What factors will gear more consumers to be more comfortable in
                having a virtual health assistant strapped to their wrist?
              </div>
              <div className="mb-4">
                <div>
                  <StaticImage
                    alt="Softmatter"
                    src="../../images/news/envolved/Health-and-Wellness-Wearables.jpg"
                  />
                </div>
                <div className="my-4">
                  Smartwatches were rather simple gadgets when they initially
                  reached the market in the early 2010s, mostly targeted at
                  monitoring exercises for sports fans and fitness geeks. Since
                  then, these wearables have come a long way - they now do more
                  than just track your steps. Evolved from a futuristic
                  accessory to ubiquitous health & wellness wearable - they
                  assist health maintenance by detecting and monitoring vitals,
                  while allowing for multi-device integration both at work and
                  within the home.
                </div>
                <div className="mb-4">
                  According to a report by Counterpoint – despite the global
                  impact of the COVID-19 pandemic, global smartwatch and
                  wristable shipments increased by 1.5% in comparison to the
                  previous year, with price shift moving premium. The market is
                  expected to increase to 14.5% by 2024 despite the high price
                  tag attached.
                </div>
                <div className="mb-4">
                  As people become more health-conscious in a post-virus world
                  and remote health monitoring becomes a necessity; smartwatch
                  and wristable manufacturers will continue to shift their focus
                  to healthcare rich features for their next phase of growth.
                  But how would such a device fit into a wearer’s experience of
                  health technology? What factors will gear more consumers to be
                  more comfortable in having a virtual health assistant strapped
                  to their wrist?
                </div>

                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Engineering Human Factors
                  </div>
                  <div className="mb-4">
                    In addition to their implication as an innovative,
                    market-disruptive device, smartwatches and wristables are
                    seamlessly designed to appeal to consumers interested in
                    wearables. Plenty of people own and wear smartwatches or
                    fitness trackers. But for devices worn for an extended
                    period of time, as with smartwatches and wristables -
                    comfort is paramount to ensure adherence and long-term use.
                    Proper execution of human factors and usability studies is
                    essential in the design of such wearables to satisfy
                    technical requirements and robust fit - and in some
                    industries, a mandated part of the product development
                    process.
                  </div>
                  <div className="mb-4">
                    Softmatter places specific emphasis on product designs that
                    champion an ultra-comfortable user experience. Our highly
                    experienced engineering design team has contributed to
                    projects ranging the entire product development spectrum -
                    from conception to production. By thoughtfully pairing
                    design and engineering, we build textile-based wearables
                    that are simple and inexpensive to produce, comfortable and
                    unobtrusive to its users.
                  </div>
                  <div className="mb-4">
                    Dedicated to solving some of the toughest smart soft
                    engineering challenges, a large component of our work
                    involves technical textiles in the form of complex design
                    structures utilizing advanced techniques such as engineered
                    knits and woven elastics. We’ve worked across the board in
                    terms of materials and constructions to achieve parameters
                    across aesthetics, composition, elasticity and fit. Given
                    our expertise with wearables, we’re able to integrate smart
                    features to textiles in the form of electronics & sensors –
                    allowing for better functionality and miniaturization.
                  </div>
                  <div className="mb-4">
                    At Softmatter, we work towards collaborating with consumer
                    technology brands with the goal of advancing the wrist worn
                    wearable market. This has enabled us in developing
                    world-class platforms for the evolving movement of wearable
                    technology: the textile itself.
                  </div>
                  <div className="mb-4">
                    Our smart textile technologies combined with user-centric
                    interfaces can produce a variety of exciting new products.
                    By partnering with our team of skilled engineering experts,
                    we guide you through each step of the development process.
                  </div>
                  <div className="mb-4">So, what factors do we consider?</div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Ergonomics and Usability
                  </div>
                  <div className="mb-4">
                    Softmatter root’s all of our product development efforts in
                    user-centered design practices that mitigate discomfort and
                    prevent poor wearer outcomes. Engineered wrist straps with
                    advanced textiles such as flat knit and woven elastics allow
                    for the creation of entirely new comfortable next-to-skin
                    structures without hindering functionality and long-term
                    use.
                  </div>
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/news/envolved/Considerations-01.jpg"
                    />
                  </div>
                  <div className="mb-4">
                    Engineered Flat knits enable lightweight, breathable complex
                    structures with specific inhomogeneous textures and
                    complicated geometries. The traditional method of developing
                    bespoke forms consisted of several production stages (cut,
                    sew, welding) with significantly increased time and cost for
                    production. By comparison, in engineered flat knit
                    technology, the production of complex, seamless
                    three-dimensional shapes is manufactured in a fast and
                    waste-free manner.
                  </div>
                  <div className="mb-4">
                    The manufacturing format precisely engineers’ yarns and
                    fabric variations solely required for load, and the product
                    is developed through a combination of multiple knit
                    structures, allowing for a seamless fit transition. The 18gg
                    3D CNC knitting process sets up for precise width and
                    thickness control in required locations, along with linear
                    modulus control for form fit in a range of wrist sizes with
                    minimal skin pressure. Other improvements include the
                    exclusions of buckles and clasps - to create a closed
                    anatomical form that mimics the shape of the wrist. The
                    result? A lighter, breathable zero-waste wrist strap that
                    offers a more precise, second-skin feel.
                  </div>
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/news/envolved/3D-Knits-01-01.jpg"
                    />
                  </div>
                  <div className="mb-4">
                    We are responsible for numerous breakthroughs in smart yarn
                    knitting and engineered knit over the last few years and
                    home to the largest Flatbed Knitting facility in South Asia.
                    A facility that fuels much of our knit innovation.
                    Leveraging Flat, Circular, and Warp knitting techniques we
                    create products built for optimal comfort and fit-to-form.
                    Our highly-skilled engineers and developers deliver a
                    competitive difference with our on-site manufacturing
                    facilities committing to ISO 9001 and 13485 standards.
                  </div>
                  <div className="mb-4">
                    Actuators or electrodes integration: Our next-to-skin
                    integrations of actuators and electrodes offers sense
                    stimuli from the environment and also reacts to them. These
                    include phase change materials, shape memory materials, and
                    touch textile applications.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Our focus on aesthetics and form build
                  </div>
                  <div className="mb-4">
                    Smartwatches that fit the aesthetic needs of modern fashion
                    centric consumer can better meet user expectations. Product
                    creation is not just about function. Your product’s form
                    needs to stand up to the sleek, modern aesthetic of today’s
                    consumer style choices.
                  </div>

                  <div className="mb-4">
                    Our soft woven knit material with a combination of jacquard,
                    tuck stitches and stitch transfer techniques help impact
                    shape and function. The functional fit feels like a natural
                    extension of the body for flexible stability and won’t
                    interfere with the user’s movements or mobility.
                  </div>
                  <div className="mb-4">
                    As it stands now, a lack of visual and pricing variety is
                    holding smartwatches back. Our product designers help
                    start-ups with little to no visual branding to assess the
                    marketplace and establish key visual elements to
                    differentiate from competitors.
                  </div>
                  <div className="mb-4">
                    For businesses with well-defined visual branding, our team
                    can seamlessly integrate new product designs with existing
                    brand elements for a featherweight, formfitting and
                    virtually seamless product.
                  </div>
                  <div className="mb-4">
                    We also offer varied surface modifications for soft
                    circuitry devices from water repellency and other resistive
                    properties to enhanced grip for performance application, and
                    silicon flocking for elevated hand feel.
                  </div>
                  <div className="mb-4">
                    Despite the worldwide impact of the COVID-19 pandemic,
                    global smartwatches and wristables shipments had continued
                    to grow in 2020 as reported by Counterpoint. Their study
                    indicates a rise of 1.5% in 2020 for smartwatches with price
                    trends moving premium. As smartwatches continue to lean to
                    the healthcare space at a time when remote health monitoring
                    becomes increasingly important - younger and older people
                    will only continue to become more health-conscious in a
                    post-virus world.
                  </div>
                  <div className="mb-4">
                    It's critical to select a product development/engineering
                    partner that will help you satisfy user-centric needs with
                    still time to market in this heavily competitive industry.
                    Do you want to make technology that prioritizes
                    user-centered architecture and quality? Connect with us to
                    get your wearable device production underway.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">References</div>
                  <div className="mb-4">
                    <a
                      href="https://www.counterpointresearch.com/smartwatch-market-grows-13-yoy-q1-2022-apple-stays-first-samsung-solidifies-second-place/"
                      target="_blank"
                      className="text-mint"
                    >
                      1.
                      smartwatch-market-grows-13-yoy-q1-2022-apple-stays-first-samsung-solidifies-second-place
                    </a>
                  </div>
                  <div className="mb-4">
                    <a
                      href="https://www.counterpointresearch.com/global-smartwatch-shipments-rise-1-5-2020-price-trends-going-premium/"
                      target="_blank"
                      className="text-mint"
                    >
                      2.
                      global-smartwatch-shipments-rise-1-5-2020-price-trends-going-premium
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Evolved
